@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap');
@import url('./tailwind.css');

body {
  background-color: #f1f1f1;
}

.navbar::before {
  content: "";
  border-radius: 40px 40px 0 0;
  position: absolute;
  width: 100%;
  height: 50px;
  top: 20px;
  background-color: white;
  z-index: 0;
}
.navbar-carousel::-webkit-scrollbar, html::-webkit-scrollbar {
  display: none;
}
.navbar-carousel, html {
  scrollbar-width: none;
}

/* Animations */
@keyframes circles {
  from {
      width: 0;
      height: 0;
  }

  to {
      height: 250px;
      width: 400px;
  }
}

@keyframes bar {
  from {
    width: 0;
  }
  to {
    width: 90%;
  }
}

@keyframes fadeUp {
  from {}
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
